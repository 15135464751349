import React, { Fragment } from "react";
import { useParams } from "react-router-dom";

import EmptyData from "Components/EmptyData";
import Loading from "Components/Loading/Loading";

import useFetchData from "hooks/useFetchDataWithDep";

import { useDispatch, useSelector } from "react-redux";
import { handleChangeData } from "../../redux/datasheetSlicer";

const SheetOfTaximeterPage = () => {
  const { code } = useParams();
  const dispatch = useDispatch();
  const { refresh } = useSelector((state) => state.datasheet);

  const url = `/back/taximeter/${code}/datasheet`;
  const { data, isLoading, isError } = useFetchData(url, refresh);

  const taximeterSheet = getRowsFromRes(data);

  if (isLoading) return <Loading />;
  if (isError) return <EmptyData />;

  dispatch(handleChangeData("taximeterSheet"));

  return (
    <Fragment>
      {/* <pre>{JSON.stringify(taximeterSheet, null, 2)}</pre> */}
    </Fragment>
  );
};

export default SheetOfTaximeterPage;

const getRowsFromRes = (data) => {
  try {
    return data.data.results.data;
  } catch {
    return [];
  }
};
